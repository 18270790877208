/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify';
import pinia from '../stores';
import router from '../router';

import piniaPersist from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';

// Types
import type { App } from 'vue';

// Add the persist plugin
pinia.use(piniaPersist);
pinia.use(Sentry.createSentryPiniaPlugin());

export function registerPlugins (app: App) {
  // Initialize Sentry
  Sentry.init({
    app,
    dsn: 'https://2ded9e33241a967fc972b68ed46ed17a@o70043.ingest.us.sentry.io/4508395208704000',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.messaking\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app
    .use(vuetify)
    .use(router)
    .use(pinia);
}
