export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/buildhome/repo/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/forgot-password',
    name: '/forgot-password',
    component: () => import('/opt/buildhome/repo/src/pages/forgot-password.vue'),
    /* no children */
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/opt/buildhome/repo/src/pages/login.vue'),
    /* no children */
  },
  {
    path: '/profile',
    name: '/profile',
    component: () => import('/opt/buildhome/repo/src/pages/profile.vue'),
    /* no children */
  },
  {
    path: '/reset-password',
    name: '/reset-password',
    component: () => import('/opt/buildhome/repo/src/pages/reset-password.vue'),
    /* no children */
  },
  {
    path: '/send-message',
    name: '/send-message',
    component: () => import('/opt/buildhome/repo/src/pages/send-message.vue'),
    /* no children */
  },
  {
    path: '/sign-up',
    name: '/sign-up',
    component: () => import('/opt/buildhome/repo/src/pages/sign-up.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

